import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ResourceChildren } from '../_class/resource-children';
import { Resource } from '../_class/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourceChildrenService {

  private resource = `/${environment.endpoint}/v1/userresourcechildren`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  Grant(parent: Resource, child: Resource) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.post<Resource>(this.resource, { parent_id: parent.id, child_id: child.id }, { headers });
  }

  Revoke(permission: ResourceChildren) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.delete<string>(`${this.resource}/${permission.id}`, { headers });
  }
}
