import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { App } from '../_class/app';

interface RequestOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe: 'response';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
}

@Injectable({
  providedIn: 'root'
})

export class AppService {

  private resource = `/${environment.endpoint}/v1/app`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  GetAll(options?: RequestOptions) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<App[]>(this.resource, { headers, ...options });
  }

  GetById(id: number, options?: RequestOptions) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<App>(`${this.resource}/${id}`, { headers, ...options });
  }

  Update(id: number, data: App, options?: RequestOptions) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.put<App>(`${this.resource}/${id}`, data, { headers, ...options });
  }
}
