import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgbCollapseModule, NgbModalModule, NgbPaginationModule, NgbTooltipModule, NgbToastModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgJsonEditorModule } from 'ang-jsoneditor'

import { AppComponent } from './app.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { LoginComponent } from './page/login/login.component';
import { EmpresaAdminComponent } from './empresa/empresa-admin/empresa-admin.component';
import { FichaAdminComponent } from './ficha/ficha-admin/ficha-admin.component';
import { EvaluacionAdminComponent } from './evaluacion/evaluacion-admin/evaluacion-admin.component';
import { DispositivoAdminComponent } from './dispositivo/dispositivo-admin/dispositivo-admin.component';
import { ToastsContainerComponent } from './page/toasts-container/toasts-container.component';

import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
import { EvaluacionViewComponent } from './evaluacion/evaluacion-view/evaluacion-view.component';
import { FichaViewComponent } from './ficha/ficha-view/ficha-view.component';
import { AppAdminComponent } from './app/app-admin/app-admin.component';
import { AppViewComponent } from './app/app-view/app-view.component';
import { ConfigComponent } from './page/config/config.component';
import { AuthAdminComponent } from './user/auth-admin/auth-admin.component';
import { FichaSearchComponent } from './ficha/ficha-search/ficha-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
registerLocaleData(localeCL);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    EmpresaAdminComponent,
    FichaAdminComponent,
    EvaluacionAdminComponent,
    DispositivoAdminComponent,
    ToastsContainerComponent,
    EvaluacionViewComponent,
    FichaViewComponent,
    AppAdminComponent,
    AppViewComponent,
    ConfigComponent,
    AuthAdminComponent,
    FichaSearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbCollapseModule,
    NgbPaginationModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbTabsetModule,
    NgSelectModule,
    FontAwesomeModule,
    LoadingBarHttpClientModule,
    NgJsonEditorModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-CL' }],
  bootstrap: [AppComponent],
  entryComponents: [LoginComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    // library.addIcons()
  }
}
