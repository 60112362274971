import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private resource = 'auth';
  private _session: any;
  private _autoLogout: any;

  constructor(
    private http: HttpClient,
  ) {
    if (sessionStorage[environment.client.name] !== undefined) {
      this._session = JSON.parse(sessionStorage[environment.client.name]);
      console.log(`Se logea en :${this.getSecondForExpire() / 1000} segundos`)
      this._autoLogout = setTimeout(() => { this.Logout(); }, this.getSecondForExpire());
    }
  }

  onLoginSuccess: EventEmitter<any> = new EventEmitter();
  onLoginFail: EventEmitter<any> = new EventEmitter();
  onLogoutSuccess: EventEmitter<any> = new EventEmitter();
  onLogoutFail: EventEmitter<any> = new EventEmitter();

  async Login({ username, password }) {
    const form = {
      username,
      password,
      grant_type: 'password',
      client_id: environment.client.name,
      client_secret: environment.client.secret
    };
    try {
      const session = await this.http.post<any>(`${environment.endpoint}/${this.resource}/token`, form).toPromise();
      const expire = (session.expire_in * 1000) + Date.now();
      this._session = { ...session, expire };
      sessionStorage[environment.client.name] = JSON.stringify(this._session);
      if (this._autoLogout) {
        clearTimeout(this._autoLogout);
      }
      this._autoLogout = setTimeout(() => { this.Logout() }, this.getSecondForExpire());
      this.onLoginSuccess.next(session.scope);
    } catch (error) {
      this.onLoginFail.next(error);
    }
  }

  Logout() {
    if (this._session) {
      delete this._session;
      delete sessionStorage[environment.client.name];
      if (this.onLogoutSuccess) {
        this.onLogoutSuccess.next();
      }
    } else {
      this.onLogoutFail.next();
    }
  }

  isExpire(): boolean {
    return !this.isValid();
  }

  isValid(): boolean {
    if (this._session) {
      return Date.now() < this._session.expire;
    } else {
      return false;
    }
  }

  getSession() {
    return { ...this._session };
  }

  getScope() {
    return { ...this._session.scope };
  }

  getToken() {
    if (this._session) {
      const [type, content, sign] = this._session.token.split('.');
      return {
        type: JSON.parse(atob(type)),
        content: JSON.parse(atob(content)),
        sign
      };
    }
  }

  getSecondForExpire() {
    if (this._session) {
      return this._session.expire - Date.now();
    } else {
      return 0;
    }
  }

  getTimeforExpire() {
    return new Date(this.getSecondForExpire());
  }
}
