import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Resource } from '../_class/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  private resource = `/${environment.endpoint}/v1/userresource`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  GetAll(params?: HttpParams) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Resource[]>(this.resource, { headers, params, observe: 'response' });
  }

  GetByPk(pk: number, params?) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Resource>(`${this.resource}/${pk}`, { headers, params });
  }

  GetResources() {
    return this.GetByPk(1, { expand: 'children' });
  }

  GetRoles() {
    return this.GetByPk(2, { expand: 'resourceChildren.child' });
  }

  GetClients() {
    return this.GetByPk(80, { expand: 'resourceChildren.child' });
  }
}
