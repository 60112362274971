import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  username;
  constructor(private session: AuthService) { }

  ngOnInit() {
    this.session.onLoginSuccess.subscribe(({ name }) => {
      this.username = name;
    });
    if (this.session.isValid()) {
      this.username = this.session.getScope().name;
    }
  }

  logout() {
    console.log('logout');
    this.session.Logout();
  }
}
