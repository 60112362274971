import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpresaAdminComponent } from './empresa/empresa-admin/empresa-admin.component';
import { FichaAdminComponent } from './ficha/ficha-admin/ficha-admin.component';
import { FichaViewComponent } from './ficha/ficha-view/ficha-view.component';
import { EvaluacionAdminComponent } from './evaluacion/evaluacion-admin/evaluacion-admin.component';
import { DispositivoAdminComponent } from './dispositivo/dispositivo-admin/dispositivo-admin.component';
import { EvaluacionViewComponent } from './evaluacion/evaluacion-view/evaluacion-view.component';
import { AppAdminComponent } from './app/app-admin/app-admin.component';
import { AppViewComponent } from './app/app-view/app-view.component';
import { ConfigComponent } from './page/config/config.component';
import { AuthAdminComponent } from './user/auth-admin/auth-admin.component';


const routes: Routes = [
  { path: 'empresa', component: EmpresaAdminComponent },
  {
    path: 'ficha', children: [
      { path: '', component: FichaAdminComponent },
      { path: ':id', component: FichaViewComponent }
    ]
  },
  {
    path: 'evaluacion', children: [
      { path: '', component: EvaluacionAdminComponent },
      { path: ':id', component: EvaluacionViewComponent },
    ]
  },
  { path: 'dispositivo', component: DispositivoAdminComponent },
  {
    path: 'app', children: [
      { path: "", component: AppAdminComponent },
      { path: ":id", component: AppViewComponent }
    ]
  },
  {
    path: 'config',
    component: ConfigComponent
  },
  {
    path: 'user/auth',
    component: AuthAdminComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
