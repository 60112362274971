import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EvaluacionService } from 'src/app/_service/evaluacion.service';
import { Evaluacion } from 'src/app/_class/evaluacion';

@Component({
  selector: 'app-evaluacion-view',
  templateUrl: './evaluacion-view.component.html',
  styleUrls: ['./evaluacion-view.component.scss']
})
export class EvaluacionViewComponent implements OnInit {
  @Input('evaId') evaId;
  model: Evaluacion;
  preguntas = [];
  optionsJsonEditor = {
    mode: "view",
    expandAll: true,
    // language: 'es-CL'
  }
  constructor(
    private route: ActivatedRoute,
    private evaluacionService: EvaluacionService
  ) { }

  async ngOnInit() {
    console.log(this.evaId)
    this.evaId = this.evaId || +this.route.snapshot.paramMap.get('id')
    this.model = await this.evaluacionService.GetByPk(this.evaId,
      { 'expand': 'tipo,preguntas.item,preguntas.alternativas,params' }
    ).toPromise();
    // this.model.preguntas.forEach(pregunta => {
    //   pregunta.alternativas && pregunta.alternativas.forEach(alternativa => {
    //     this.preguntas.push({
    //       tipo: pregunta.item && pregunta.item.nombre,
    //       pregunta: pregunta.descripcion,
    //       alternativa: alternativa.descripcion,
    //       correcta: alternativa.correcta,
    //       eva_id: this.model.eva_id,
    //       pre_id: pregunta.pre_id,
    //       alt_id: alternativa.alternativa
    //     });
    //   });
    // });
  }

}
