import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Dispositivo } from '../_class/dispositivo';

@Injectable({
  providedIn: 'root'
})
export class DispositivoService {

  private resource = `/${environment.endpoint}/v1/dispositivo`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  GetAll(params?: HttpParams) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Dispositivo[]>(this.resource, { headers, params, observe: 'response' });
  }
}
