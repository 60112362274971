import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Empresa } from '../_class/empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private resource = `/${environment.endpoint}/v1/empresa`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }


  GetAll(params?: HttpParams | {}) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Empresa[]>(this.resource, { headers, params, observe: 'response' });
  }

}
