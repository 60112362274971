import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FichaService } from 'src/app/_service/ficha.service';
import { Ficha } from 'src/app/_class/ficha';

@Component({
  selector: 'app-ficha-view',
  templateUrl: './ficha-view.component.html',
  styleUrls: ['./ficha-view.component.scss']
})
export class FichaViewComponent implements OnInit {
  @Input() id: number;
  model: Ficha = {}
  show = {
    respuestas: false,
    parametros: false
  }
  optionsJsonEditor = {
    mode: "view",
    expandAll: true,
    // language: 'es-CL'
  }
  constructor(
    private route: ActivatedRoute,
    private fichaService: FichaService
  ) { }

  async ngOnInit() {
    this.id = this.id || +this.route.snapshot.paramMap.get('id')
    this.model = await this.fichaService.GetByPk(this.id, {
      expand: 'alternativas.pregunta.item,evaluacion,params'
    }).toPromise();
    this.show.respuestas = this.model.alternativas.length != 0
    this.show.parametros = this.model.params != null
  }



}
