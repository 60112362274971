import { Component, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/_service/toast.service';

@Component({
  selector: 'toasts',
  templateUrl: './toasts-container.component.html',
  host: { '[class.ngb-toasts]': 'true' }
})

export class ToastsContainerComponent {
  constructor(public toastService: ToastService) { }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
