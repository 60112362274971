import { Component, OnInit, OnDestroy } from '@angular/core';
import { Ficha } from 'src/app/_class/ficha';
import { FichaService } from 'src/app/_service/ficha.service';
import { Pagination } from 'src/app/_class/pagination';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-ficha-admin',
  templateUrl: './ficha-admin.component.html',
  styleUrls: ['./ficha-admin.component.scss']
})
export class FichaAdminComponent implements OnInit {
  showSearch = false;
  models: Ficha[];
  pagination: Pagination = new Pagination({
    expand: ['evaluacion', 'trabajador', 'dispositivo.empresa', 'pais'],
    sort: '-fic_id'
  });
  subLogin: Subscription;

  constructor(
    private fichaService: FichaService,
    private session: AuthService
  ) { }

  ngOnInit() {
    this.LoadData();
    this.subLogin = this.session.onLoginSuccess.subscribe(() => {
      this.LoadData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async LoadData() {
    const { body, headers } = await await this.fichaService.GetAll(this.pagination.params).toPromise();
    this.models = body;
    this.pagination.Load(headers);
  }

  addFilter(e: HttpParams) {
    this.pagination.params = e;
    this.LoadData()
  }
}
