import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/_service/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/_service/toast.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('content', { static: true })

  private contentTpl: TemplateRef<any>;
  openModal = false;

  model = {
    username: '',
    password: ''
  };

  modalRef: NgbModalRef;

  constructor(
    private session: AuthService,
    private modalService: NgbModal,
    private toast: ToastService
  ) { }

  timerLogin;

  ngOnInit() {

    if (this.session.isExpire()) {
      this.open();
    } else {
      this.timerLogin = setTimeout(() => { this.open() }, this.session.getSecondForExpire());
    }

    this.session.onLoginSuccess.subscribe(({ name }) => {
      this.toast.show(`Bienvenido ${name}`);
      this.modalRef.close();
      this.openModal = false;
      if (this.timerLogin) { clearTimeout(this.timerLogin); }
      this.timerLogin = setTimeout(() => { this.open() }, this.session.getSecondForExpire());
    });

    this.session.onLogoutSuccess.subscribe(() => {
      this.open();
    })

    this.session.onLoginFail.subscribe(()=>{
      this.toast.warning("Usuario o contraseña invalida");
    })
  }

  onSubmit() {
    this.session.Login(this.model);
  }

  open() {
    if (!this.openModal) {
      this.modalRef = this.modalService.open(this.contentTpl, {
        backdrop: 'static',
        keyboard: false,
        size: 'sm'
      });
      this.openModal = true;
    }
  }
}
