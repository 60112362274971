import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/_service/resource.service';
import { Resource } from 'src/app/_class/resource';
import * as _ from 'lodash';
import { ResourceChildrenService } from 'src/app/_service/resource-children.service';

@Component({
  selector: 'app-auth-admin',
  templateUrl: './auth-admin.component.html',
  styleUrls: ['./auth-admin.component.scss']
})
export class AuthAdminComponent implements OnInit {

  clients: Resource;
  roles: Resource;
  resources: Resource;
  dataResource: (Resource & { checked: boolean })[]
  searchInput: string = ""
  authorization: Resource;
  dataAuthorization: (Resource & { checked: boolean })[]

  constructor(
    private resourceServices: ResourceService,
    private resourceChildrenServices: ResourceChildrenService
  ) { }

  async ngOnInit() {
    var [resources, roles, clients] = await Promise.all([
      this.resourceServices.GetResources().toPromise(),
      this.resourceServices.GetRoles().toPromise(),
      this.resourceServices.GetClients().toPromise()
    ]);
    this.clients = <Resource>clients;
    this.roles = <Resource>roles;
    this.resources = <Resource>resources;
    this.dataResource = _(this.resources.children).map(r => { return { checked: false, ...r } }).orderBy(['resource']).value()
  }

  sumAdd(simbol: string, data: (Resource & { checked: boolean })[]) {
    if (data) {
      let countTrue = _.filter(data, { checked: true });
      if (countTrue.length > 0) {
        return ` (${simbol}${countTrue.length})`;
      }
    }
  }

  async AllowPermission() {
    let metaData = _(this.dataResource)
      .filter({ checked: true });
    await Promise.all(
      metaData
        .map(r => { return this.resourceChildrenServices.Grant(this.authorization, r).toPromise() })
        .value()
    )
    metaData.each(x => { x.checked = false });
    this.setAuthorization(this.authorization);
  }

  async DenyPermission() {
    console.log(this.authorization)
    await Promise.all(_(this.dataAuthorization)
      .filter({ checked: true })
      .map(r => _.find(this.authorization.resourceChildren, { child_id: r.id }))
      .map(rc => this.resourceChildrenServices.Revoke(rc).toPromise())
      .value());
    this.setAuthorization(this.authorization);

  }

  showResourceAvailability({ id, resource }: Resource) {
    return resource.includes(this.searchInput) && !_.some(this.dataAuthorization, { id })
  }

  async setAuthorization(resource: Resource) {
    this.authorization = resource
    this.authorization = await this.resourceServices.GetByPk(resource.id, { expand: 'resourceChildren' }).toPromise();
    this.dataAuthorization = _(this.authorization.resourceChildren)
      .map(rc => {
        let r = _.find(this.resources.children, { id: rc.child_id });
        return { checked: false, ...r }
      })
      .orderBy(['resource'])
      .value()
  }

}
