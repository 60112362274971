import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EmpresaService } from 'src/app/_service/empresa.service';
import { Empresa } from 'src/app/_class/empresa';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { EvaluacionService } from 'src/app/_service/evaluacion.service';
import { formatDate } from 'src/app/_class/helper';


@Component({
  selector: 'app-ficha-search',
  templateUrl: './ficha-search.component.html',
  styleUrls: ['./ficha-search.component.scss']
})
export class FichaSearchComponent implements OnInit {

  //#region Attributes
  @Output('search') search = new EventEmitter<HttpParams>();

  filter = {
    fichas: [],
    evaluaciones: [],
    empresas: [],
    dispositivos: [],
    traRut: [],
    trabajador: {
      nombre: null,
      paterno: null,
      materno: null
    },
    fecha: {
      inicio: null,
      termino: null
    }
  }

  request = {
    empresas: {
      data: [],
      term: []
    },
    evaluaciones: {
      data: [],
      term: []
    },
  }

  //#endregion
  //#region Conctructores
  constructor(
    private empresaService: EmpresaService,
    private evaluacionServices: EvaluacionService
  ) { }
  //#endregion
  async searchEmpresa(term: string) {
    if (!this.request.empresas.term.includes(term.toLowerCase())) {
      this.request.empresas.term.push(term.toLowerCase())
      var { body } = await this.empresaService.GetAll({ 'filter[nombre][like]': term }).toPromise();
      this.request.empresas.data = _(this.request.empresas.data).concat(body).uniqBy('emp_id').value()
    }
  }

  async searchEvaluaciones(term: string) {
    if (!this.request.evaluaciones.term.includes(term.toLowerCase())) {
      this.request.evaluaciones.term.push(term.toLowerCase())
      var { body } = await this.evaluacionServices.GetAll({ 'filter[nombre][like]': term }).toPromise();
      this.request.evaluaciones.data = _(this.request.evaluaciones.data).concat(body).uniqBy('eva_id').value()
    }
  }


  ngOnInit() {
  }

  Search() {
    var params: HttpParams = new HttpParams();
    params = this.filter.fichas.reduce((p, x) => p.append(`filter[ficId][in][]`, x), params)
    params = this.filter.evaluaciones.reduce((p, x) => p.append(`filter[evaId][in][]`, x.eva_id), params)
    params = this.filter.empresas.reduce((p, x) => p.append(`filter[empId][in][]`, x.emp_id), params)
    params = this.filter.dispositivos.reduce((p, x) => p.append(`filter[disId][in][]`, x), params)
    params = this.filter.traRut.reduce((p, x) => p.append(`filter[traRut][in][]`, x), params)
    if (this.filter.trabajador.nombre) {
      params = params.set(`filter[traNombre][like]`, this.filter.trabajador.nombre)
    }
    if (this.filter.trabajador.paterno) {
      params = params.set(`filter[traPaterno][like]`, this.filter.trabajador.paterno)
    }
    if (this.filter.trabajador.materno) {
      params = params.set(`filter[traMaterno][like]`, this.filter.trabajador.materno)
    }
    if (this.filter.fecha.inicio) {
      params = params.set(`filter[creado][gte]`, this.filter.fecha.inicio)
    }
    if (this.filter.fecha.termino) {
      params = params.set(`filter[creado][lte]`, this.filter.fecha.termino)
    }
    this.search.emit(params)
  }

  Clear() {
    this.filter = {
      fichas: [],
      evaluaciones: [],
      empresas: [],
      dispositivos: [],
      traRut: [],
      trabajador: {
        nombre: null,
        paterno: null,
        materno: null
      },
      fecha: {
        inicio: null,
        termino: null
      }
    }
    var params: HttpParams = new HttpParams();
    this.search.emit(params)

  }
}
