import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmpresaService } from 'src/app/_service/empresa.service';
import { Empresa } from 'src/app/_class/empresa';
import { Pagination } from 'src/app/_class/pagination';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-empresa-admin',
  templateUrl: './empresa-admin.component.html',
  styleUrls: ['./empresa-admin.component.scss']
})
export class EmpresaAdminComponent implements OnInit, OnDestroy {

  models: Empresa[];
  pagination: Pagination = new Pagination();

  constructor(
    private empresaService: EmpresaService,
    private session: AuthService
  ) { }

  subLogin: Subscription;

  ngOnInit() {
    this.LoadData();
    this.subLogin = this.session.onLoginSuccess.subscribe(() => {
      this.LoadData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async LoadData() {
    const { body, headers } = await this.empresaService.GetAll(this.pagination.params).toPromise();
    this.models = body;
    this.pagination.Load(headers);
  }
}
