import { Component, OnInit, OnDestroy } from '@angular/core';
import { Dispositivo } from 'src/app/_class/dispositivo';
import { DispositivoService } from 'src/app/_service/dispositivo.service';
import { Pagination } from 'src/app/_class/pagination';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dispositivo-admin',
  templateUrl: './dispositivo-admin.component.html',
  styleUrls: ['./dispositivo-admin.component.scss']
})
export class DispositivoAdminComponent implements OnInit, OnDestroy {

  models: Dispositivo[];
  pagination: Pagination = new Pagination({
    expand: ['empresa', 'tipo'],
    sort: '-dis_id'
  });

  constructor(
    private dispositivoService: DispositivoService,
    private session: AuthService
  ) { }

  subLogin: Subscription;

  ngOnInit() {
    this.LoadData();
    this.subLogin = this.session.onLoginSuccess.subscribe(() => {
      this.LoadData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async LoadData() {
    const { body, headers } = await this.dispositivoService.GetAll(this.pagination.params).toPromise();
    this.models = body;
    this.pagination.Load(headers);
  }

}
