import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Evaluacion } from '../_class/evaluacion';
@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {

  private resource = `/${environment.endpoint}/v1/rvevaluacion`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  GetAll(params?: HttpParams | {}) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Evaluacion[]>(this.resource, { headers, params, observe: 'response' });
  }

  GetByPk(pk: number, params?) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Evaluacion>(`${this.resource}/${pk}`, { headers, params });
  }
}
