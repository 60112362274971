import { Component, OnInit, TemplateRef } from '@angular/core';
import { Evaluacion } from 'src/app/_class/evaluacion';
import { EvaluacionService } from 'src/app/_service/evaluacion.service';
import { Pagination } from 'src/app/_class/pagination';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EvaluacionViewComponent } from '../evaluacion-view/evaluacion-view.component';

@Component({
  selector: 'app-evaluacion-admin',
  templateUrl: './evaluacion-admin.component.html',
  styleUrls: ['./evaluacion-admin.component.scss']
})
export class EvaluacionAdminComponent implements OnInit {
  evaId: number = null;
  models: Evaluacion[];
  pagination: Pagination = new Pagination({
    expand: ['tipo'],
    sort: '-eva_id'
  });
  constructor(
    private evaluacionService: EvaluacionService,
    private session: AuthService,
    private modalService: NgbModal
  ) { }

  subLogin: Subscription;

  ngOnInit() {
    this.LoadData();
    this.subLogin = this.session.onLoginSuccess.subscribe(() => {
      this.LoadData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async LoadData() {
    const { body, headers } = await this.evaluacionService.GetAll(this.pagination.params).toPromise();
    this.models = body;
    this.pagination.Load(headers);
  }

  open(content: TemplateRef<any>, evaId: number) {
    this.evaId = evaId
    var modalRef = this.modalService.open(content, { size: "xl" })
    modalRef.result.then((result) => {
      console.log({ result })
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log({ reason })
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
