import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Ficha } from '../_class/ficha';

@Injectable({
  providedIn: 'root'
})
export class FichaService {

  private resource = `/${environment.endpoint}/v1/rvficha`;

  constructor(
    private http: HttpClient,
    private session: AuthService
  ) { }

  GetAll(params?: HttpParams) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Ficha[]>(this.resource, { headers, params, observe: 'response' });
  }

  GetByPk(pk: number, params?: HttpParams | any) {
    const { token_type, token } = this.session.getSession();
    const headers = new HttpHeaders({ Authorization: `${token_type} ${token}` });
    return this.http.get<Ficha>(`${this.resource}/${pk}`, { headers, params });
  }

}
