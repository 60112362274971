import { Component, OnInit } from '@angular/core';
import { App } from 'src/app/_class/app';
import { Pagination } from 'src/app/_class/pagination';
import { AppService } from 'src/app/_service/app.service';
import { AuthService } from 'src/app/_service/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-app-admin',
  templateUrl: './app-admin.component.html',
  styleUrls: ['./app-admin.component.scss']
})
export class AppAdminComponent implements OnInit {

  models: App[];
  pagination: Pagination = new Pagination();

  constructor(
    private appService: AppService,
    private session: AuthService
  ) { }

  subLogin: Subscription;

  ngOnInit() {
    this.LoadData();
    this.subLogin = this.session.onLoginSuccess.subscribe(() => {
      this.LoadData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async LoadData() {
    const { body, headers } = await this.appService.GetAll({ params: this.pagination.params, observe: 'response' }).toPromise();
    this.models = body;
    this.pagination.Load(headers);
  }
}
